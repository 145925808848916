<template>
  <div class="container">
    <div class="content">
      <div class="header">公众平台账号授权</div>
      <div class="imgCon">
        <img :src="testImg" alt />
        <p class="tips">使用公众平台绑定的管理员个人微信号扫描</p>
        <div class="details">
          <div class="left">
            <img :src="testImg" alt />
          </div>
          <div class="right">
            <p class="systemName">红领巾系统</p>
            <div class="detailItem">
              <span>标签</span>
              <div class="detailInfo">行业应用 智能建站 互动营销 分销系统</div>
            </div>
            <div class="detailItem">
              <span>简介</span>
              <div class="detailInfo">微信小程序开发商</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import testImg from '@/assets/images/test.jpg'
export default {
  data() {
    return {
      testImg: testImg
    }
  },
  methods: {}
}
</script>
<style lang="less" scoped>
@import url('./index.less');
</style>
